/* App theme starts */
:root {
  --light1-color: #f8f8f8;
  --light2-color: #ffffff;
  --light1-contrast-color: #111111;
  --light2-contrast-color: #1d1d1d;
  --light-invert-color: #000000;
  /* --dark1-color: #212129;
  --dark2-color: #15141a;
  --dark1-contrast-color: #212129;
  --dark2-contrast-color: #212129; */
}

.light-theme {
  --bg-primary-color: var(--light1-color);
  --bg-secondary-color: var(--light2-color);
  --text-color: #5d5d55;
  --heading-color: #282828;
  --shadow-color: rgba(27, 24, 25, 0.1);
  --card-shadow-color: rgba(27, 24, 25, 0.04);
  --invert-image: invert(0%);
  --invert-image-hover: invert(100%);
  --bg-primary-contrast-color: var(--light1-contrast-color);
  --bg-secondary-contrast-color: var(--light2-contrast-color);
  --text-contrast-color: #ababab;
  --heading-contrast-color: #ffffff;
  --input-text-color: #cccccc;
  --input-text-color-focus: #495057;
  --input-border-color: #ced4da;
  --btn-hover-bg-color: #343a40;
  --btn-hover-bg-contrast-color: #f8f9fa;
}

/* .dark-theme {
  --bg-primary-color: var(--dark1-color);
  --bg-secondary-color: var(--dark2-color);
  --text-color: #818195;
  --heading-color: #ebebeb;
  --shadow-color: rgba(199, 199, 199, 0.05);
  --invert-image: invert(100%);
  --invert-image-hover: invert(0%);
  --bg-primary-contrast-color: var(--dark1-contrast-color);
  --bg-secondary-contrast-color: var(--dark2-contrast-color);
  --text-contrast-color: #818195;
  --heading-contrast-color: #ebebeb;
} */

::selection {
  background-color: var(--bg-primary-contrast-color);
  color: var(--heading-contrast-color);
}
/* App theme ends */

/* Rotate screen */
.rotate {
  display: none;
}
@media (max-height: 575px) and (orientation: landscape) {
  .rotate {
    display: block;
    background-color: var(--bg-primary-color);
    background-image: url(../../images/rotate-lock.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 48px 48px;
    position: fixed;
    top: 0;
    left: 0;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    transition: all 0.3s ease;
    z-index: 999;
  }
  .rotate span {
    color: var(--heading-color);
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 3.4rem;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Loading */
.loading {
  background-color: var(--bg-primary-color);
  background-image: url(../../images/loader.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
}
@media (min-width: 768px) {
  .loading {
    background-size: 70%;
  }
}
@media (min-width: 992px) {
  .loading {
    background-size: 40%;
  }
}
